import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { IconThumbUp } from '@tabler/icons-react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import CurrencyInput from 'react-currency-input-field';
import { sendUserMessage, createContact } from '../hooks/sendeasyAPI';

const LikeButton = ({ vehicle }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [offer, setOffer] = useState('');
    const [urlParams, setUrlParams] = useState({});
    const whatsappInputRef = useRef(null);

    useEffect(() => {
        // Captura todos os parâmetros da URL
        const params = new URLSearchParams(window.location.search);
        const paramsObj = {};
        params.forEach((value, key) => {
            paramsObj[key] = value;
        });
        setUrlParams(paramsObj);
    }, []);

    useEffect(() => {
        if (isModalOpen && whatsappInputRef.current) {
            const iti = intlTelInput(whatsappInputRef.current, {
                initialCountry: 'br',
                separateDialCode: true,
                utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
            });

            whatsappInputRef.current.addEventListener('countrychange', () => {
                setWhatsapp(iti.getNumber());
            });

            return () => {
                iti.destroy();
            };
        }
    }, [isModalOpen]);

    const handleLikeClick = () => {
        setIsModalOpen(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const sendToken = '17210567903080xq5epTxvtfWskuWJFDXxjoYIZDX5XMOBseXm71KU';
        const contactToken = '17123437933778TfxPcJNCSrX12SLW8q6TKqqfI6ku8mM8LTjQcTOl';
        const message = `Oi ${name}, recebemos sua mensagem de que deseja comprar ${vehicle.MAKE} ${vehicle.MODEL} e gostaria de fazer uma oferta de R$ ${offer}. Vamos analisar e te respondemos em breve.`;

        try {
            await sendUserMessage(sendToken, whatsapp, message);
            await createContact(contactToken, {
                name,
                email: '',
                number: whatsapp,
                tags: ['Lead', 'Vehicle Interest', ...Object.values(urlParams)],
                extraInfo: [
                    { name: 'Vehicle', value: `${vehicle.MAKE} ${vehicle.MODEL}` },
                    { name: 'Offer', value: `R$ ${offer}` },
                    ...Object.entries(urlParams).map(([key, value]) => ({ name: key, value })),
                ],
            });
            alert('Mensagem enviada com sucesso!');
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Erro ao enviar mensagem. Tente novamente.');
        }
    };

    const handleWhatsappChange = (e) => {
        setWhatsapp(e.target.value);
    };

    return (
        <>
            <button
                type="button"
                className="p-2 rounded-full text-red-50 bg-red-600 hover:bg-red-200 transition-colors duration-300 shadow-sm border border-red-100"
                onClick={handleLikeClick}
                aria-label='Like Vehicle'
            >
                <IconThumbUp size={24} />
            </button>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Like Vehicle"
                className="max-w-md mx-auto p-6 rounded-lg shadow-lg z-[60] overflow-auto max-h-screen bg-white border border-red-500"
                overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-[50]"
                onAfterOpen={() => {
                    const iti = intlTelInput(whatsappInputRef.current, {
                        initialCountry: 'br',
                        separateDialCode: true,
                        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
                    });

                    whatsappInputRef.current.addEventListener('countrychange', () => {
                        setWhatsapp(iti.getNumber());
                    });
                }}
            >
                <div className="flex justify-between items-center mb-4">
                    <div className="text-2xl font-black uppercase truncate">
                        <h2>Gostou?</h2>
                        <span className="text-red-600 text-sm block font-bold"> Faça uma oferta!</span>
                        <strong className="text-xl font-bold uppercase truncate text-gray-600">R$ {vehicle.PRICE ? vehicle.PRICE.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : 'N/A'}</strong>
                    </div>
                    <img
                        src={vehicle.IMAGES && vehicle.IMAGES.IMAGE_URL[0] ? vehicle.IMAGES.IMAGE_URL[0] : '/images/car-placeholder.webp'}
                        alt={vehicle.MODEL}
                        className="w-20 h-20 object-cover rounded-md ml-2"
                    />
                </div>
                <small className="text-xs font-normal capitalize block w-full mt-1 mb-4 opacity-65">
                    {vehicle.MAKE}&nbsp;
                    {vehicle.MODEL ? vehicle.MODEL.length > 32 ? `${vehicle.MODEL.substring(0, 32)}...` : vehicle.MODEL : ''}
                </small>
                <form onSubmit={handleSubmit} className="space-y-4 font-medium text-gray-600">
                    <div>
                        <label className="block text-xs font-bold text-gray-700">Nome</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-xs font-bold text-gray-700">WhatsApp</label>
                        <input
                            type="tel"
                            ref={whatsappInputRef}
                            value={whatsapp}
                            onChange={handleWhatsappChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-xs font-bold text-gray-700">Oferta</label>
                        <CurrencyInput
                            id="offer"
                            name="offer"
                            placeholder="R$ 0,00"
                            decimalsLimit={2}
                            prefix="R$ "
                            decimalSeparator=","
                            groupSeparator="."
                            value={offer}
                            onValueChange={(value) => setOffer(value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-red-600 text-white py-2 rounded-md hover:bg-red-700 transition-colors font-bold"
                    >
                        Enviar Oferta
                    </button>
                </form>
            </Modal>
        </>
    );
};

export default LikeButton;

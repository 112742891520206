// useUTMParams.js
import { useEffect, useState } from 'react';

const useUTMParams = (search) => {
  const [utmParams, setUTMParams] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(search);
    const utms = {};
    // Captura todos os parâmetros da URL sem restrições
    params.forEach((value, key) => {
      utms[key] = value;
    });
    setUTMParams(utms);
  }, [search]);
  console.log('utmParams', utmParams);
  return utmParams;
};

export default useUTMParams;

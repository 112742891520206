import React, { useState, useEffect, Suspense } from 'react';
import useUTMParams from './useUTMParams';
import Slider from 'react-slick';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SkeletonLoader from './SkeletonLoader';

const LazyImage = React.lazy(() => import('./LazyImage'));

export default function Hero() {
    const utmParams = useUTMParams(window.location.search);
    const [type, setType] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        const preloadImage = (imageUrl) => {
            const link = document.createElement('link');
            link.rel = 'preload';
            link.as = 'image';
            link.href = imageUrl;
            document.head.appendChild(link);
        };
        switch (utmParams.utm_term) {
            case 'suv':
                setType('um SUV');
                setImage('tracker.webp');
                break;
            case 'coupe':
                setType('um coupé novo');
                setImage('camaro.webp');
                break;
            case 'esportivo':
                setType('um esportivo novo');
                setImage('camaro.webp');
                break;
            case 'sedan':
                setType('um sedan novo');
                setImage('cruze.webp');
                break;
            case 'hatch':
                setType('um hatchback novo');
                setImage('onix.webp');
                break;
            case 'van':
                setType('uma minivan nova');
                setImage('equinox.webp');
                break;
            case 'pickup':
                setType('uma pickup nova');
                setImage('s10.webp');
                break;
            case 'eletrico':
                setType('um carro elétrico');
                setImage('bolt.webp');
                break;
            default:
                setType('um carro novo');
                setImage('tracker.webp');
        }
        // Preload the determined image
        if (image) {
            preloadImage(image);
        }
    }, [utmParams.utm_term, image]);

    function NextArrow(props) {
        const { onClick } = props;
        return (
            <div
                className="absolute z-10 bottom-[40%] lg:bottom-[50%] right-0 cursor-pointer m-2"
                onClick={onClick}
            >
                <IconChevronRight size={24} />
            </div>
        );
    }

    function PrevArrow(props) {
        const { onClick } = props;
        return (
            <div
                className="absolute z-10 bottom-[40%] lg:bottom-[50%] left-0 cursor-pointer m-2"
                onClick={onClick}
            >
                <IconChevronLeft size={24} />
            </div>
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        shaddow: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 9999,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const slides = [
        {
            id: 1,
            title: `<strong class='block sm:text-6xl'>É hora de comprar ${type}<span class='text-red-500'>.</span></strong>`,
            subtitle: "<span class='text-sm'>Isocar, o seu destino confiável para carros de qualidade em Osasco. <p>Explore nosso vasto estoque de veículos e encontre as melhores opções de financiamento no mercado automotivo.</p></span>",
            imageUrl: `${image}`,
            button2Text: "Agendar Test Drive",
        },
        {
            id: 2,
            title: "<strong class='block sm:text-6xl'>O maior estoque de <span class='text-red-500'>0km</span> de Osasco.</strong>",
            subtitle: "<span class=''>ZERO km rodados. Encontre o carro dos seus sonhos com a garantia de quem é referência no mercado automotivo.</span>",
            imageUrl: "x6.webp",
            button1Text: "Ver lista",
            button2Text: "Ver mais",
        }
    ];

    const isMobile = window.matchMedia('(max-width: 1024px)').matches;

    return (
        <div className="w-screen relative pt-28 lg:pt-8">
            <div className="relative overflow-hidden bg-gradient-to-b from-gray-100/20 h-full bg-white">
                {isMobile ? (
                    <>
                        <div className="w-full pb-32 h-[700px] mx-auto">
                            <div className="relative inset-0 max-w-auto xl:max-w-6xl flex flex-col items-end justify-around h-full lg:flex-row mx-auto px-6">
                                {/* div textos */}
                                <Suspense fallback={<SkeletonLoader width="600px" height="300px" />}>
                                    <div className="lg:pt-4 h-auto">
                                        <div className="mx-auto max-w-7xl">
                                            <h1 className="mt-10 font-bold tracking-tight text-gray-900 text-5xl w-full">
                                                <strong className='block sm:text-6xl'>É hora de comprar {type}<span className="text-red-500">.</span></strong>
                                            </h1>
                                            <p className="mt-6 text-gray-600 text-md">
                                                <span className='text-sm'>Isocar, o seu destino confiável para carros de qualidade em Osasco.</span>
                                            </p>
                                        </div>
                                        <div
                                            className="absolute inset-y-0 right-1 -z-30 -mr-64 w-[3330%] skew-x-[-32deg] bg-white shadow-xl shadow-gray-600/10 ring-1 ring-gray-50 md:-mr-20 lg:-mr-36 h-screen"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </Suspense>
                                {/* div img */}
                                <div className="flex items-end justify-end w-full h-full">
                                    <div className="hidden absolute bottom-0 -right-2 -z-10 lg:inline">
                                        <svg width="532" height="500" viewBox="0 0 2660 2272" fill="white" xmlns="http://www.w3.org/2000/svg" stroke="rgba(7, 23, 50, .1)" strokeWidth="80" strokeLinecap="butt" strokeLinejoin="butt" className="overflow-visible">
                                            <path d="M344 372L0 1712L274 1992L548 2272L2328 2248L2660 972L1676 0L344 372Z" fill="url(#paint0_linear_482_4)" />
                                        </svg>
                                    </div>
                                    <Suspense fallback={<SkeletonLoader width="600px" height="300px" />}>
                                        <LazyImage src={`/categories/mobile/${image}`} alt={type} width={'100%'} height={'100%'} />
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <Slider {...settings} className='h-full w-full'>
                            {slides.map((slide) => (
                                <div key={slide.id} className="w-full pb-32 h-[700px] mx-auto">
                                    <div className="relative inset-0 max-w-auto xl:max-w-6xl flex flex-col items-end justify-around h-full lg:flex-row mx-auto px-6">
                                        {/* div textos */}
                                        <Suspense fallback={<SkeletonLoader width="600px" height="300px" />}>
                                            <div className="lg:pt-4 h-auto">
                                                <div className="mx-auto max-w-7xl">
                                                    <h1 className="mt-10 font-bold tracking-tight text-gray-900 text-6xl w-full">
                                                        <span dangerouslySetInnerHTML={{ __html: slide.title }} />
                                                    </h1>
                                                    <p className="mt-6 pb-10 text-gray-600 text-md">
                                                        <span dangerouslySetInnerHTML={{ __html: slide.subtitle }} />
                                                    </p>
                                                    <div className="my-8 flex items-center gap-x-6 hidden">
                                                        {slide.button1Text && (
                                                            <a href="#" className="rounded-full bg-red-600 px-4 py-2.5 text-xs lg:text-sm font-semibold text-white shadow-sm hover:bg-red-500">
                                                                {slide.button1Text}
                                                            </a>
                                                        )}
                                                        <a href="#" className="text-sm font-semibold text-gray-900">
                                                            {slide.button2Text} <span aria-hidden="true">→</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div
                                                    className="absolute inset-y-0 right-1 -z-30 -mr-64 w-[3330%] skew-x-[-32deg] bg-white ring-1 ring-gray-50 md:-mr-20 lg:-mr-36 h-screen"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </Suspense>
                                        {/* div img */}
                                        <div className="flex items-end justify-end w-full h-full">
                                            <div className="hidden absolute bottom-0 -right-2 -z-10 lg:inline">
                                                <svg width="532" height="500" viewBox="0 0 2660 2272" fill="white" xmlns="http://www.w3.org/2000/svg" stroke="rgba(7, 23, 50, .1)" strokeWidth="80" strokeLinecap="butt" strokeLinejoin="butt" className="overflow-visible">
                                                    <path d="M344 372L0 1712L274 1992L548 2272L2328 2248L2660 972L1676 0L344 372Z" fill="url(#paint0_linear_482_4)" />
                                                </svg>
                                            </div>
                                            <Suspense fallback={<SkeletonLoader width="600px" height="300px" />}>
                                                <LazyImage src={`/categories/${slide.imageUrl}`} alt={type} width={'100%'} height={'100%'} />
                                            </Suspense>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </>
                )}
            </div>
        </div>
    );
}

import axios from 'axios';

const API_BASE_URL = 'https://api.sendeasy.app';

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const sendUserMessage = async (sendToken, number, body, sectorId = null) => {
  const formData = new FormData();
  formData.append('number', number);
  formData.append('body', body);
  if (sectorId) {
    formData.append('sector', sectorId);
  }

  try {
    const response = await api.post('/message/send', formData, {
      headers: { 'Authorization': `Bearer ${sendToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

export const createContact = async (contactToken, contactDetails) => {
  try {
    const response = await api.post('/contact', contactDetails, {
      headers: {
        'Authorization': `Bearer ${contactToken}`,
        'Content-Type': 'application/json'
      }
    });
    console.log('Contact created:', JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error('Error creating contact:', error);
    throw error;
  }
};

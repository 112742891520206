import { IconLoader2 } from '@tabler/icons-react';
import React from 'react';

const SkeletonLoader = ({ width, height }) => {
  return (
    <IconLoader2 className="animate-spin h-10 w-10 text-gray-200" style={{ width: width, height: height }} stroke={0.16} />
  );
}

export default SkeletonLoader;

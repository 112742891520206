import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IconChevronLeft,
  IconChevronRight,
  IconManualGearbox,
  IconGauge,
  IconGasStation,
  IconCalendar,
  IconPhone,
  IconCar,
  IconBrandHeadlessui,
  IconPalette,
  IconCurrencyDollar,
  IconThumbUp,
  IconInfoCircle,
} from "@tabler/icons-react";
import Modal from "react-modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "tailwindcss/tailwind.css";
import HomeCategories from "./HomeCategories";
import LikeButton from "./LikeButton";

const pageSize = 9;

const CarList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [carsData, setCarsData] = useState([]);
  const [currentCars, setCurrentCars] = useState([]);
  const [filteredCarsCount, setFilteredCarsCount] = useState(0);
  const [filters, setFilters] = useState({ brand: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedCar, setSelectedCar] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchCarsData();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const type = query.get("type") || "";
    const brand = query.get("brand") || "";
    setFilters({ type, brand });
  }, [location.search]);

  useEffect(() => {
    applyFilters();
  }, [filters, currentPage, carsData]);

  const fetchCarsData = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(
        "https://isocar-worker.betalab.workers.dev/generate-json"
      );
      const data = await response.json();
      setCarsData(data.ADS.AD); // Atualiza para usar o novo formato
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch data");
      setLoading(false);
      console.error(err);
    }
  };

  const applyFilters = () => {
    console.log("Applying filters on carsData:", carsData);
    let filtered = carsData.filter(
      (car) =>
        (filters.brand
          ? car.MAKE.toLowerCase() === filters.brand.toLowerCase()
          : true) &&
        (filters.type
          ? car.BODY_TYPE.toLowerCase() === filters.type.toLowerCase()
          : true)
    );
    console.log("Filtered cars:", filtered);
    setFilteredCarsCount(filtered.length);
    const pageCars = filtered.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    setCurrentCars(pageCars);

    // Check if there are no filtered items and reset the current page if necessary
    if (filtered.length === 0 && currentPage > 1) {
      setCurrentPage(1);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = (type, value) => {
    const newFilters = { ...filters, [type]: value };
    setFilters(newFilters);
    setCurrentPage(1); // Reset to first page on filter change

    // Update URL with new filters
    const queryParams = new URLSearchParams(newFilters).toString();
    navigate(`?${queryParams}`);
  };

  const openModal = (car) => {
    setSelectedCar(car);
  };

  const closeModal = () => {
    setSelectedCar(null);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  if (loading)
    return (
      <div className="flex flex-col justify-center items-center h-full">
        <img
          className="h-24 w-auto mt-32"
          src="/images/logo-isocar.webp"
          alt="Isocar logo"
        />
        <p className="text-center text-gray-700 text-sm font-medium m-2 my-4 mb-32">
          Carregando veículos...
        </p>
      </div>
    );
  if (error) return <p className="text-center text-xl text-red-500">{error}</p>;

  return (
    <div className="bg-gray-100 min-h-screen py-8">
      <div className="container mx-auto p-4">
        <HomeCategories home={false} />
        {currentCars.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {currentCars.map((car) => (
              <VehicleCard
                key={car.ID}
                vehicle={car}
                onClick={() => openModal(car)}
              />
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-700 text-sm font-medium m-2 my-4">
            Nenhum veículo encontrado para os filtros selecionados.
          </p>
        )}
        {filteredCarsCount > pageSize && (
          <Pagination
            currentPage={currentPage}
            totalCount={filteredCarsCount}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      {selectedCar && (
        <Modal
          isOpen={!!selectedCar}
          onRequestClose={closeModal}
          contentLabel="Car Details"
          className="max-w-[calc(100vw-16px)] md:max-w-xl inset-0 bg-white p-6 rounded-lg shadow-lg z-[60] overflow-auto max-h-screen"
          overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-[50]"
        >
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 z-[60]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h2 className="text-md font-black uppercase truncate">
            {selectedCar.TITLE
              ? selectedCar.TITLE.length > 32
                ? selectedCar.TITLE.slice(0, 32) + "..."
                : selectedCar.TITLE
              : "Título não disponível"}
            <small className="text-red-700 mb-2 font-medium block text-xs">
              {selectedCar.CONDITION}
            </small>
          </h2>
          {selectedCar.IMAGES &&
          selectedCar.IMAGES.IMAGE_URL &&
          selectedCar.IMAGES.IMAGE_URL.length > 1 ? (
            <Slider {...sliderSettings}>
              {selectedCar.IMAGES.IMAGE_URL.map((img, index) => (
                <div
                  key={index}
                  className="h-auto w-32 overflow-hidden flex justify-center items-center"
                >
                  <img
                    src={img}
                    alt={selectedCar.TITLE}
                    className="object-cover rounded-lg"
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <div className="h-auto w-full overflow-hidden flex justify-center items-center">
              <img
                src={
                  selectedCar.IMAGES && selectedCar.IMAGES.IMAGE_URL
                    ? selectedCar.IMAGES.IMAGE_URL[0]
                    : "/images/car-placeholder.webp"
                }
                alt={selectedCar.TITLE}
                className="object-cover rounded-lg"
              />
            </div>
          )}
          <div className="flex w-full justify-end">
            <LikeButton vehicle={selectedCar} />
          </div>
          <div className="text-xs">
            <p className="text-gray-700 mb-2 sr-only">
              <strong>Descrição:</strong>{" "}
              {selectedCar.DESCRIPTION
                ? selectedCar.DESCRIPTION
                : "Descrição não disponível"}
            </p>
            <p className="font-bold mb-1">Acessórios</p>
            <small className="text-gray-700 mb-2 capitalize">
              {selectedCar.ACCESSORIES
                ? selectedCar.ACCESSORIES.split(",").join(", ")
                : "Acessórios não disponíveis"}
            </small>
            <div className="grid grid-cols-3 gap-x-4 mt-4 capitalize">
              <small className="text-gray-700 mb-2 flex flex-wrap align-middle items-center truncate gap-1">
                <IconCalendar className="h-3 w-3" />{" "}
                {selectedCar.YEAR ? selectedCar.YEAR : ""}{" "}
                {selectedCar.FABRIC_YEAR ? `/ ${selectedCar.FABRIC_YEAR}` : ""}
              </small>
              <small className="text-gray-700 mb-2 flex flex-wrap align-middle items-center truncate gap-1">
                <IconCar className="h-3 w-3" /> {selectedCar.CONDITION}
              </small>
              <small className="text-gray-700 mb-2 flex flex-wrap align-middle items-center truncate gap-1">
                <IconGauge className="h-3 w-3" /> {selectedCar.MILEAGE} km
              </small>
              <small className="text-gray-700 mb-2 flex flex-wrap align-middle items-center truncate gap-1">
                <IconGasStation className="h-3 w-3" /> {selectedCar.FUEL}
              </small>
              <small className="text-gray-700 mb-2 flex flex-wrap align-middle items-center truncate gap-1">
                <IconManualGearbox className="h-3 w-3" /> {selectedCar.GEAR}
              </small>
              <small className="text-gray-700 mb-2 flex flex-wrap align-middle items-center truncate gap-1">
                <IconBrandHeadlessui className="h-3 w-3" /> {selectedCar.DOORS}{" "}
                Portas
              </small>
              <small className="text-gray-700 mb-2 flex flex-wrap align-middle items-center truncate gap-1">
                <IconPalette className="h-3 w-3" /> {selectedCar.COLOR}
              </small>
              <small className="text-gray-700 mb-2 flex flex-wrap align-middle items-center truncate gap-1">
                <IconCurrencyDollar className="h-3 w-3" /> R${" "}
                {selectedCar.PRICE.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                })}
              </small>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

const VehicleCard = ({ vehicle, onClick }) => {
  const imageUrl =
    vehicle.IMAGES && vehicle.IMAGES.IMAGE_URL && vehicle.IMAGES.IMAGE_URL[0]
      ? !vehicle.IMAGES.IMAGE_URL[0].includes("null" || "undefined")
        ? vehicle.IMAGES.IMAGE_URL[0]
        : "/images/logo-isocar.webp"
      : "/images/logo-isocar.webp";

  return (
    <div className="p-6 bg-white/80 backdrop-blur-md rounded-xl shadow-lg relative">
      <img
        src={imageUrl}
        alt={vehicle.MODEL}
        className="w-full h-64 object-cover rounded-xl cursor-pointer"
        onClick={onClick}
      />
      <h2 className="capitalize text-xs mt-4 font-bold text-red-600 truncate">
        <strong>{vehicle.MAKE}</strong>
      </h2>
      <h3 className="capitalize text-md font-semibold text-gray-800 truncate">
        {vehicle.MODEL}
      </h3>
      <h4 className="text-2xl font-bold text-gray-950 mt-4">
        R$ {vehicle.PRICE.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}
      </h4>
      <div className="my-2">
        <p className="text-xs text-gray-600 my-2 flex items-center gap-1">
          <IconManualGearbox size={16} /> {vehicle.GEAR}
        </p>
        <p className="text-xs text-gray-600 my-2 flex items-center gap-1">
          <IconGauge size={16} /> {vehicle.MILEAGE} km
        </p>
        <p className="text-xs text-gray-600 my-2 flex items-center gap-1">
          <IconCalendar size={16} /> {vehicle.YEAR}
        </p>
        <p className="text-xs text-gray-600 my-2 flex items-center gap-1">
          <IconGasStation size={16} /> {vehicle.FUEL}
        </p>
        <p className="text-xs text-gray-600 my-2 flex items-center gap-1">
          <IconPhone size={16} /> {vehicle.PHONE}
        </p>
      </div>
      <div className="absolute bottom-6 right-6 flex items-center gap-2">
        <div className="flex gap-2">
          <LikeButton vehicle={vehicle} />
          <button
            type="button"
            className="p-2 rounded-full text-gray-50 bg-gray-800 hover:bg-gray-200 transition-colors duration-300 shadow-sm border border-gray-100"
            onClick={onClick}
            aria-label="Like Vehicle"
          >
            <IconInfoCircle size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};

const Pagination = ({ currentPage, totalCount, pageSize, onPageChange }) => {
  const pageCount = Math.ceil(totalCount / pageSize);
  const pagesToShow = 5; // Number of pages to show in pagination
  const halfPageToShow = Math.floor(pagesToShow / 2);

  const startPage = Math.max(currentPage - halfPageToShow, 1);
  const endPage = Math.min(startPage + pagesToShow - 1, pageCount);

  const paginationPages = [];

  for (let i = startPage; i <= endPage; i++) {
    paginationPages.push(i);
  }

  return (
    <div className="flex justify-center space-x-2 mt-6">
      {startPage > 1 && (
        <>
          <button
            onClick={() => onPageChange(1)}
            className={`px-4 py-2 border rounded-md ${
              currentPage === 1
                ? "bg-red-500 text-white"
                : "bg-white text-red-500"
            } hover:bg-red-100 transition-colors duration-300`}
          >
            1
          </button>
          <span className="px-4 py-2 border rounded-md bg-white text-red-500">
            ...
          </span>
        </>
      )}
      {paginationPages.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          disabled={currentPage === number}
          className={`px-4 py-2 border rounded-md ${
            currentPage === number
              ? "bg-red-500 text-white"
              : "bg-white text-red-500"
          } hover:bg-red-100 transition-colors duration-300`}
        >
          {number}
        </button>
      ))}
      {endPage < pageCount && (
        <>
          <span className="px-4 py-2 border rounded-md bg-white text-red-500">
            ...
          </span>
          <button
            onClick={() => onPageChange(pageCount)}
            className={`px-4 py-2 border rounded-md ${
              currentPage === pageCount
                ? "bg-red-500 text-white"
                : "bg-white text-red-500"
            } hover:bg-red-100 transition-colors duration-300`}
          >
            {pageCount}
          </button>
        </>
      )}
    </div>
  );
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow z-60`}
      onClick={onClick}
      style={{ ...style, display: "block" }}
    >
      <IconChevronRight className="bg-black/80 text-white" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow z-60`}
      onClick={onClick}
      style={{ ...style, display: "block" }}
    >
      <IconChevronLeft className="bg-black/80 text-white" />
    </div>
  );
}

export default CarList;

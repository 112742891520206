import React, { useRef, useEffect, useState } from 'react';

const AnimatedRangeSlider = ({
    label,
    min,
    max,
    step,
    defaultValue,
    onChange,
    coin = false,
    textColor = '#FFFFFF',             // Texto branco
    labelColor = '#FFFFFF',            // Label branco
    sliderTrackColor = '#FFC0CB',      // Rosa claro para o track não preenchido
    sliderThumbColor = '#FFFFFF',      // Bolinha do slider branca
    sliderFillColor = '#FF69B4',       // Rosa para a barra preenchida do slider
    thumbBorderColor = '#FF1493'       // Rosa borda da bolinha do slider
}) => {
    const sliderRef = useRef(null);
    const displayRef = useRef(null);
    const [displayValue, setDisplayValue] = useState(defaultValue); //

    useEffect(() => {
        setDisplayValue(coin ? formatCurrency(defaultValue) : defaultValue); // Definir valor inicial formatado
    }, [defaultValue, coin]);

    const handleSliderChange = (e) => {
        const value = e.target.value;
        onChange(value); // Atualizar o estado pai
        setDisplayValue(coin ? formatCurrency(value) : value); // Atualizar o estado de exibição
    };

    const formatCurrency = (value) => {
        // Converter para um número antes de formatar
        const numericValue = Number(value);
        return new Intl.NumberFormat('pt-BR', { 
            style: 'currency', 
            currency: 'BRL',
            minimumFractionDigits: 2 // Isso garante duas casas decimais
        }).format(numericValue);
    };    

    const thumbStyle = {
        height: '24px',
        width: '24px',
        borderRadius: '12px',
        backgroundColor: sliderThumbColor,
        border: `2px solid ${thumbBorderColor}`,
        boxSizing: 'border-box'
    };

    // Calculate thumb position for correct coloring behind the thumb
    const fillPercentage = ((defaultValue - min) / (max - min)) * 100;

    return (
        <div className='flex flex-col gap-4'>
            <div className="flex justify-between items-end">
                <label htmlFor="slider"
                    className="text-green-300 text-right font-bold text-xs mt-1 -bottom-4 flex gap-1 items-center justify-start" style={{ color: labelColor }}> {label} </label>
                <div
                    id='slider'
                    ref={displayRef}
                    className="text-right text-lg font-bold"
                    style={{ color: textColor }}>
                    {displayValue}
                </div>
            </div>
            <input
                id='slider'
                ref={sliderRef}
                type="range"
                min={min}
                max={max}
                step={step}
                defaultValue={displayValue}
                onChange={handleSliderChange}
                className="slider"
                style={{
                    background: `linear-gradient(90deg, ${sliderFillColor} ${fillPercentage}%, ${sliderTrackColor} ${fillPercentage}%)`,
                    WebkitAppearance: 'none',
                    width: '100%',
                    height: '6px',
                    borderRadius: '5px',
                    outline: 'none'
                }}
            />
            <style>
                {`
                    input[type="range"]::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        appearance: none;
                        ${Object.entries(thumbStyle).map(([key, value]) => `${key}: ${value};`).join(' ')}
                    }

                    input[type="range"]:focus::-webkit-slider-thumb {
                        outline: none;
                    }

                    input[type="range"]::-moz-range-thumb {
                        ${Object.entries(thumbStyle).map(([key, value]) => `${key}: ${value};`).join(' ')}
                    }
                `}
            </style>
        </div>
    );
};

export default AnimatedRangeSlider;
import React, { useState, useEffect, useRef } from 'react';
import useUTMParams from './useUTMParams';
import { sendUserMessage, createContact } from 'sendeasy-api-sdk';
import { IconChecklist } from '@tabler/icons-react';
import AnimatedRangeSlider from './AnimatedRangeSlider';
import 'intl-tel-input/build/css/intlTelInput.css';

const CalculateLoan = () => {
  const utmParams = useUTMParams(window.location.search);
  const [type, setType] = useState('suv');
  const [entryAmount, setEntryAmount] = useState('10000');
  const [carValue, setCarValue] = useState('100000');
  const [whatsapp, setWhatsapp] = useState('');
  const [fullName, setFullName] = useState('');
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const whatsappInputRef = useRef(null);

  const getSectorId = (term) => {
    switch (term) {
      case 'hatch':
        return 102;
      case 'suv':
        return 102;
      case 'pickup':
        return 102;
      default:
        return 102;
    }
  };

  useEffect(() => {
    if (utmParams.utm_term) {
      setType(utmParams.utm_term);
    }
  }, [utmParams]);

  useEffect(() => {
    const checkFormValidity = () => {
      if (whatsapp && fullName && entryAmount && carValue && type) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    };
    checkFormValidity();
  }, [whatsapp, fullName, entryAmount, carValue, type]);

  const carImages = {
    suv: '/categories/x6.webp',
    coupe: '/categories/camaro.webp',
    sedan: '/categories/cruze.webp',
    esportivo: '/categories/cruze-sport.webp',
    hatch: '/categories/onix.webp',
    van: '/categories/spin.webp',
    pickup: '/categories/silverado.webp',
    eletrico: '/categories/bolt.webp',
  };

  const carApprove = {
    suv: 87,
    coupe: 83,
    sedan: 92,
    esportivo: 84,
    hatch: 89,
    van: 94,
    pickup: 96,
    eletrico: 87,
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleWhatsappChange = (event) => {
    setWhatsapp(event.target.value);
  };

  const leadCaptureAndSendMessage = async () => {
    setLoading(true);
    setStatusMessage('');
    const tags = Object.keys(utmParams).map(key => utmParams[key]);
    const sectorId = getSectorId(utmParams.utm_term);

    const formattedWhatsapp = `55${whatsapp.replace(/\D/g, '')}`;

    console.log('utmParams:', utmParams);
    console.log('utm_term:', utmParams.utm_term);
    console.log('sectorId:', sectorId);

    try {
      const sendToken = '17210567903080xq5epTxvtfWskuWJFDXxjoYIZDX5XMOBseXm71KU';
      const contactToken = '17123437933778TfxPcJNCSrX12SLW8q6TKqqfI6ku8mM8LTjQcTOl';
      const message = `Olá ${fullName}, já estamos trabalhando em sua ficha para aprovar um financiamento de ${type} no valor de R$ ${carValue} com entrada de R$ ${entryAmount}. Aguarde nosso retorno.`;

      await sendUserMessage(sendToken, formattedWhatsapp, message, sectorId);

      const contactDetails = {
        name: fullName,
        number: formattedWhatsapp,
        tags: ['CAMPANHA FINANCIAMENTO', ...tags],
        customFields: {
          'Tipo de veículo': type,
          'Valor de entrada': entryAmount,
          'Valor do veículo': carValue
        }
      };

      await createContact(contactToken, contactDetails);

      console.log('Contact created successfully');
      setStatusMessage('Solicitação de simulação de financiamento enviada com sucesso.');
    } catch (error) {
      console.error('An error occurred:', error);
      setStatusMessage('Failed to process your request.');
    }
    setLoading(false);
  };

  return (
    <>
      <svg
        className="absolute inset-0 -z-10 h-full w-full rotate-180 horizontal-flip stroke-black/20 [mask-image:radial-gradient(100%_100%_at_top_right,black,transparent)] overflow-hidden"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-500/5">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
      </svg>
      <div className="p-4 text-white max-w-7xl mx-auto flex flex-col gap-4 py-16 justify-end align-bottom" id="simular">
        <div className="flex gap-4 gap-y-8 flex-col md:flex-row h-full items-end">
          <div className="w-full h-full hidden md:flex justify-end items-end">
            <div className="w-full">
              <div className="border border-white flex flex-row md:flex-col items-center gap-4 backdrop-blur-sm p-4 rounded-3xl shadow-md w-full min-h-32 lg:py-24 justify-between overflow-hidden bg-gradient-to-br from-stone-500/5 to-white/10">
                <div className="text-white text-2xl font-medium text-center flex flex-col items-center gap-1">
                  <h1 className="text-8xl font-black text-black text-right mt-0">
                    <span className="flex items-center gap-0 justify-start">
                      <IconChecklist size={100} className="inline-block text-green-400" />
                      {carApprove[type]}%
                    </span>
                    <span className="block text-green-500 font-medium text-sm lg:text-lg ">
                      De aprovação no financiamento de {type}<sup>*</sup>
                    </span>
                  </h1>
                </div>
                <div className="flex items-center gap-4 p-4 rounded-3xl w-full max-h-96 max-w-32 md:max-w-full justify-center">
                  <div className="hidden md:flex rounded-xl overflow-hidden h-auto w-auto flex-col items-center justify-center">
                    <img src={carImages[type]} alt="Car type" className="w-auto h-auto object-cover" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form flex flex-col gap-4 w-full p-6 px-8 rounded-3xl shadow-md bg-black/85 backdrop-blur-lg border border-white/20 lg:-ml-16 lg:-mb-6">
            <div className="py-4 flex flex-col gap-4 text-left">
              <h2 className="text-2xl lg:text-4xl font-black text-white">
                Simular Financiamento<span className="text-red-500">.</span>
              </h2>
              <p className="text-xs lg:text-sm font-normal">
                Preencha os campos abaixo para simular o financiamento para comprar seu veículo, a ISOCAR possui parceria com as melhores financeiras.
              </p>
            </div>
            {statusMessage && <p className="bg-white text-gray-700 rounded-lg p-4 text-center">{statusMessage}</p>}
            <label htmlFor="type" className="text-white text-right font-bold text-xs -bottom-4 flex gap-1 items-center justify-start">
              Tipo de veículo desejado
            </label>
            <select value={type} onChange={handleTypeChange} className="border border-white p-2 rounded-xl bg-gray-950/50 text-white backdrop-blur-md" id="type">
              <option value="suv">SUV</option>
              <option value="coupe">Coupé</option>
              <option value="sedan">Sedan</option>
              <option value="hatch">Hatch</option>
              <option value="van">Van</option>
              <option value="esportivo">Esportivo</option>
              <option value="pickup">Pickup</option>
              <option value="eletrico">Elétrico</option>
            </select>
            <AnimatedRangeSlider
              label="Valor de entrada"
              min={0}
              max={100000}
              step={500}
              coin={true}
              defaultValue={entryAmount}
              onChange={(value) => setEntryAmount(value)}
              textColor="#FFFFFF"
              labelColor="#FFFFFF"
              sliderTrackColor="#E5E7EB"
              sliderThumbColor="#10B981"
              sliderFillColor="#4ADE80"
              thumbBorderColor="#059669"
            />

            <AnimatedRangeSlider
              label="Valor do veículo"
              min={0}
              max={500000}
              step={1000}
              coin={true}
              defaultValue={carValue}
              onChange={(value) => setCarValue(value)}
              textColor="#FFFFFF"
              labelColor="#FFFFFF"
              sliderTrackColor="#E5E7EB"
              sliderThumbColor="#10B981"
              sliderFillColor="#4ADE80"
              thumbBorderColor="#059669"
            />

            <input
              required
              type="tel"
              ref={whatsappInputRef}
              value={whatsapp}
              onChange={handleWhatsappChange}
              placeholder="Digite o telefone com DDD"
              className="border border-white p-2 w-full rounded-xl bg-gray-950/50 text-white backdrop-blur-md placeholder:text-white"
              autoComplete="off"
            />
            <input
              required
              type="text"
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              placeholder="Nome completo"
              className="border border-white p-2 rounded-xl bg-gray-950/50 text-white backdrop-blur-md placeholder:text-white"
              autoComplete="off"
            />
            <button 
              onClick={leadCaptureAndSendMessage} 
              disabled={!isFormValid || loading} 
              className={`rounded-full font-bold p-4 text-black truncate ${(!isFormValid || loading) ? 'bg-gray-400 cursor-not-allowed' : 'bg-white'}`}
            >
              {loading ? 'Calculando...' : (!isFormValid ? 'Preencha todo o formulário' : '🤞 Calcular e aprovar meu crédito*')}
            </button>
          </div>
        </div>
        <div className="text-black/65 text-xs font-medium text-center mt-4">
          <sup>*</sup> Sujeito a análise de crédito e aprovação. Taxas e condições podem variar.
        </div>
      </div>
    </>
  );
};

export default CalculateLoan;

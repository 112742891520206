import React, { useState, useEffect, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from './components/Menu';
import Hero from './components/Hero';
// import FilterCars from './components/FilterCars';
import HomeCategories from './components/HomeCategories';
import CalculateLoan from './components/CalculateLoan';
import SliderBar from './components/SliderBar';
import Footer from './components/Footer';
import SkeletonLoader from './components/SkeletonLoader';
import CarList from './components/CarList';
import { IconLoader2 } from '@tabler/icons-react';
import useUTMParams from './components/useUTMParams';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const [isPreloading, setIsPreloading] = useState(true);
  const query = useQuery();
  const utmParams = useUTMParams(window.location.search);
  const [type, setType] = useState(query.get('type') || '');
  const isMobile = window.matchMedia('(max-width: 1024px)').matches;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPreloading(false);
    }, 333);

    return () => clearTimeout(timer);
  }, []);

  const preloader = (
    <div
      className={`fixed inset-0 bg-white/90  backdrop-blur-2xl flex justify-center items-center z-50 transition-opacity duration-500 ${isPreloading ? 'opacity-100' : 'opacity-0'}`}
      onAnimationEnd={() => isPreloading || setIsPreloading(null)}
    >
      <IconLoader2 className="animate-spin h-80 w-80 text-black/10 fixed inset-0 m-auto" stroke={0.16} />
      <img className="h-24 w-auto" src="/images/logo-isocar.webp" alt="Isocar logo" />
    </div>
  );

  return (
    <>
      {isPreloading && preloader}
      <Menu />
      <Suspense fallback={<SkeletonLoader width="600px" height="300px" />}>
        <Hero />
      </Suspense>
      <div className='relative -top-16 bg-black text-white'>
        <div className="absolute -top-16 left-0 w-full h-auto rotate-180" aria-hidden="true">
          <svg viewBox="0 0 2824 217" preserveAspectRatio="none" style={{ height: "64px", width: "100%" }}>
            <path d="M0 0H2824L0 217V0Z" style={{ stroke: "none", fill: "#000  " }}></path>
          </svg>
        </div>
        <HomeCategories home={true} />
      </div>
      <Suspense fallback={<SkeletonLoader width="600px" height="300px" />}>
        <div className='relative -top-16 bg-white z-40'>
          <div className="absolute -top-16 left-0 w-full h-auto rotate-180 z-20" aria-hidden="true">
            <svg viewBox="0 0 2824 217" preserveAspectRatio="none" style={{ height: "64px", width: "100%" }}>
              <path d="M0 0H2824L0 217V0Z" style={{ stroke: "none", fill: "#ffffff" }}></path>
            </svg>
          </div>
          <CalculateLoan utmParams={utmParams} />
        </div>
      </Suspense>
      <div className="relative -top-16 bg-white z-40 saturate-0">
        <Suspense fallback={<SkeletonLoader width="600px" height="300px" />}>
          <SliderBar
            dots={false}
            infinite={true}
            speed={30000}
            slidesToShow={5}
            slidesToScroll={5}
            autoplay={true}
            images={[
              '/car-logos-dataset/logos/thumb/audi.webp',
              '/car-logos-dataset/logos/thumb/bmw.webp',
              '/car-logos-dataset/logos/thumb/chevrolet.webp',
              '/car-logos-dataset/logos/thumb/fiat.webp',
              '/car-logos-dataset/logos/thumb/ford.webp',
              '/car-logos-dataset/logos/thumb/honda.webp',
              '/car-logos-dataset/logos/thumb/hyundai.webp',
              '/car-logos-dataset/logos/thumb/jeep.webp',
              '/car-logos-dataset/logos/thumb/kia.webp',
              '/car-logos-dataset/logos/thumb/mercedes-benz.webp',
              '/car-logos-dataset/logos/thumb/nissan.webp',
              '/car-logos-dataset/logos/thumb/peugeot.webp',
              '/car-logos-dataset/logos/thumb/renault.webp',
              '/car-logos-dataset/logos/thumb/toyota.webp',
              '/car-logos-dataset/logos/thumb/volkswagen.webp',
            ]}
            imageHeight={100}
          />
        </Suspense>
      </div>
      <div className="relative -top-16" id="estoque">
        <Suspense fallback={<SkeletonLoader width="600px" height="300px" />}>
          <CarList filters={utmParams} />
        </Suspense>
      </div>
      <Footer />
    </>
  );
}

export default App;

import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { IconCalculator, IconCar, IconHeadset } from '@tabler/icons-react';

const navigation = [
  { name: 'Nosso Estoque', href: '#estoque', icon: IconCar },
  { name: 'Simular Financiamento', href: '#simular', icon: IconCalculator },
]

export default function Menu() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const handleMenuItemClick = (href) => {
    if (href.startsWith('#')) {
      const targetId = href.substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <header className="bg-white/80 backdrop-blur-xl backdrop-filter fixed top-0 left-0 right-0 z-40">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex flex-1 truncate">
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a 
                key={item.name}
                href={item.href}
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuItemClick(item.href);
                }}
                className="text-xs font-semibold leading-6 text-gray-900 flex items-center gap-x-1">
                <item.icon className="h-4 w-4" aria-hidden="true" />
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        <a href="#" className="-m-1.5 p-0.5 md:p-1.5">
          <span className="sr-only">Isocar</span>
          <img className="h-8 md:h-14 w-auto" src="/images/logo-isocar.webp" alt="Isocar logo no menu" width={64} height={64} />
        </a>
        <div className="flex flex-1 justify-end">
          <a href="tel:+551136071313" className="text-red-500 font-bold flex gap-1 items-center"><IconHeadset size={16} /> 11 3607-1313</a>
          <a href="#" className="text-xs lg:text-sm truncate font-semibold leading-6 text-gray-900 flex items-center gap-x-1 hidden">
            <IconHeadset size={20} />
            Atendimento
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-white px-6 py-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-1">
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Isocar Multimarcas</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
            <div className="flex flex-1 justify-end">
              <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                Log in <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="mt-6 space-y-2">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                onClick={(e) => {
                  e.preventDefault();
                  setMobileMenuOpen(false);
                  handleMenuItemClick(item.href);
                }}
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              >
                {item.name}
              </a>
            ))}
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconArrowUpRight } from '@tabler/icons-react';
import { Suspense } from 'react';

const categories = [
    {
        name: 'Sedan',
        img: '/categories/sedan.webp',
        description: 'O Sedan é a escolha perfeita para quem busca conforto e elegância. Com um amplo espaço interno e tecnologia avançada, ele oferece uma experiência de condução excepcional para toda a família.',
    },
    {
        name: 'SUV',
        img: '/categories/suv.webp',
        description: 'Os SUVs combinam estilo e versatilidade, sendo ideais para aventuras urbanas ou off-road. Com design robusto e recursos de segurança avançados, proporcionam uma sensação de poder e confiança ao volante.',
    },
    {
        name: 'Coupe',
        img: '/categories/coupe.webp',
        description: 'Os Coupés são sinônimo de esportividade e sofisticação. Com linhas elegantes e desempenho excepcional, proporcionam uma condução emocionante e envolvente, ideal para quem busca adrenalina e estilo.',
    },
    {
        name: 'Hatch',
        img: '/categories/hatch.webp',
        description: 'Os Hatchbacks são compactos e ágeis, ideais para a vida na cidade. Com seu design moderno e economia de combustível, oferecem praticidade e estilo urbano, tornando cada viagem mais eficiente e divertida.',
    },
    {
        name: 'Minivan',
        img: '/categories/van.webp',
        description: 'As Vans são ideais para quem precisa de espaço e versatilidade. Com amplo espaço interno e capacidade para transportar passageiros ou carga, são perfeitas para viagens em família ou negócios.',
    },
    {
        name: 'Caminhonete',
        img: '/categories/pickup.webp',
        description: 'As Pickups combinam robustez e versatilidade, sendo ideais para o trabalho e lazer. Com capacidade de carga e tração nas quatro rodas, oferecem desempenho excepcional em qualquer terreno, tornando cada viagem mais produtiva e emocionante.',
    },
];

export default function HomeCategories({ home }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedCategory, setSelectedCategory] = useState('');

    const handleCategoryClick = (category) => {
        const newCategory = selectedCategory === category ? '' : category;
        setSelectedCategory(newCategory);

        // Extract UTM parameters from the current URL
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('type', newCategory);

        // Preserve existing UTM parameters
        const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
        utmParams.forEach(param => {
            const value = queryParams.get(param);
            if (value) {
                queryParams.set(param, value);
            }
        });

        navigate(`/?${queryParams.toString()}`);

        // Scroll to #estoque div
        document.getElementById('estoque').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className={`${home === true ? 'pb-28' : 'pb-14'}`}>
            <div className="mx-auto max-w-7xl">
                <h2 className="text-xl font-black text-right text-900 sm:text-2xl px-2 my-1">
                    Categorias de Veículos
                </h2>
                <p className="text-xs lg:text-sm opacity-65 text-right px-2 pb-8">
                    Encontre o veículo perfeito para o seu estilo de vida
                </p>
                <div className="mx-auto grid max-w-full grid-cols-3 gap-1 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-6">
                    {categories.map((item) => (
                        <div
                            key={item.name}
                            className="group"
                            onClick={() => handleCategoryClick(item.name)}
                        >
                            <div className={`flex flex-col items-center justify-between p-4 lg:p-8 bg-white backdrop-blur-md rounded-3xl lg:border shadow-md cursor-pointer scale-90 hover:scale-95 transition-transform duration-200 border-3 ${selectedCategory === item.name ? 'border-red-500' : 'border-white'}`}>
                                <Suspense fallback={<div>🚘</div>}>
                                    <img src={item.img} alt={item.name} className="w-auto rounded-md opacity-95 -top-4 relative" title={item.name} width={200} height={200} />
                                </Suspense>
                                <div className="flex items-end justify-between h-4 w-full absolute bottom-0 left-0 p-4 text-gray-900/20 font-extrabold text-sm lg:text-lg group-hover:text-gray-900">
                                    <div className="text-gray-900">
                                        {item.name}
                                        <p className="mt-1 text-xs leading-7 text-gray-600 sr-only">{item.description}</p>
                                    </div>
                                    <div className="group-hover:text-gray-900">
                                        <IconArrowUpRight size={20} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

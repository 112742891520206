import { React, Suspense, useEffect, useState } from 'react';
import Slider from 'react-slick';
import SkeletonLoader from './SkeletonLoader';

const SliderBar = ({ dots, infinite, speed, slidesToShow, slidesToScroll, autoplay, images, imageHeight }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        window.addEventListener('load', () => {
            setTimeout(() => {
                setIsLoaded(true);                
            }, 2000);
        });
    }, []);

    const settings = {
        dots: dots,
        infinite: infinite,
        autoplay: autoplay || true,
        speed: speed,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        easing: 'linear',
        arrows: false,
        DelayNode: 0,
        autoplaySpeed: 0,
        cssEase: 'linear',
        pauseOnHover: false,
        pauseOnFocus: false,
        swipeToSlide: true,
        swipe: true,
        touchMove: true,
        draggable: true,
        touchThreshold: 100,
        touchMove: true,
    };

    return (
        <Suspense fallback={<SkeletonLoader width="600px" height="300px" />}>
            {isLoaded && (
                <div id="slider-bar" className={`duration-3000 ease-in-out ${isLoaded ? 'opacity-100' : 'opacity-0'}`}>
                    <Slider {...settings}>
                        {images.map((image, index) => (
                            <div key={index}>
                                <img src={image} alt={`Image ${index + 1}`} className={`h-auto w-auto object-contain p-6 pointer-events-none opacity-65`} style={{ height: `${imageHeight}px` }} aria-hidden="true" height={imageHeight} width={imageHeight} title={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </Slider>
                </div>
            )}
        </Suspense>
    );
};

export default SliderBar;
